import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { MeinVorteilComponent } from './mein-vorteil/mein-vorteil.component';
import { MeineNeueITComponent } from './meine-neue-it/meine-neue-it.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WirHabenUnsVonUnserenEDVProblemenBefreitComponent } from './wir-haben-uns-von-unseren-edvproblemen-befreit/wir-haben-uns-von-unseren-edvproblemen-befreit.component';
import { IchWillKeineEDVProblemeMehrComponent } from './ich-will-keine-edvprobleme-mehr/ich-will-keine-edvprobleme-mehr.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    HomeComponent,
    MeinVorteilComponent,
    MeineNeueITComponent,
    PageNotFoundComponent,
    WirHabenUnsVonUnserenEDVProblemenBefreitComponent,
    IchWillKeineEDVProblemeMehrComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
