import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ich-will-keine-edvprobleme-mehr',
  templateUrl: './ich-will-keine-edvprobleme-mehr.component.html',
  styleUrls: ['./ich-will-keine-edvprobleme-mehr.component.scss']
})
export class IchWillKeineEDVProblemeMehrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
