import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mein-vorteil',
  templateUrl: './mein-vorteil.component.html',
  styleUrls: ['./mein-vorteil.component.scss']
})
export class MeinVorteilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
