import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainComponent } from './main/main.component';


const routes: Routes = [ { path: '',
redirectTo: '/destiny',
pathMatch: 'full'
},
{
  path: 'destiny',
  component: MainComponent },
{ path: '**', component: MainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
