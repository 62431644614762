import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meine-neue-it',
  templateUrl: './meine-neue-it.component.html',
  styleUrls: ['./meine-neue-it.component.scss']
})
export class MeineNeueITComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
