import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  showImpressum(){
    document.getElementById("impressummodal").classList.add('is-active');
  }

  hideImpressum(){
    document.getElementById("impressummodal").classList.remove('is-active');
  }

}
