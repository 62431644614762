import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wir-haben-uns-von-unseren-edvproblemen-befreit',
  templateUrl: './wir-haben-uns-von-unseren-edvproblemen-befreit.component.html',
  styleUrls: ['./wir-haben-uns-von-unseren-edvproblemen-befreit.component.scss']
})
export class WirHabenUnsVonUnserenEDVProblemenBefreitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
